import './App.css';
import Input from './components/input/Input';

function App() {
  return (
    <>
     <Input/>
    </>
  );
}

export default App;
